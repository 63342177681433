import layout from '@/Layout/index.vue'

export default {
  path: '/home',
  component: layout,
  redirect: '/home',
  meta: {
    title: 'home'
  },
  children: [
    {
      path: '/terminal/overview',
      name: 'Terminals',
      component: () => import('@/views/terminal/terminal-list/terminals.vue'),
      meta: {
        title: 'terminal-list',
        searchFlag: true,
        isAuth: true,
        parentTitle: [
          {
            title: 'app-terminal'
          }
        ]
      }
    },
    {
      path: '/terminal/remote/:id',
      name: 'RemoteTerminal',
      component: () => import('@/views/terminal/terminal-remote/index.vue'),
      meta: {
        title: 'remote-terminal',
        searchFlag: true,
        isAuth: true,
        activeMenu: '/terminal/overview',
        parentTitle: [
          {
            title: 'app-terminal'
          },
          {
            title: 'app-terminal-overview',
            path: '/terminal/overview'
          }
        ]
      }
    },
    {
      path: '/terminal/switch/log/:id',
      name: 'SwitchLog',
      component: () => import('@/views/terminal/switch-log/switch-log.vue'),
      meta: {
        title: 'switch-log',
        searchFlag: true,
        isAuth: true,
        activeMenu: '/terminal/overview',
        parentTitle: [
          {
            title: 'app-terminal'
          },
          {
            title: 'app-terminal-overview',
            path: '/terminal/overview'
          }
        ]
      }
    },
    {
      path: '/terminal/trace/log/:id',
      name: 'TraceLog',
      component: () => import('@/views/terminal/trace-log/trace-log.vue'),
      meta: {
        title: 'trace-log',
        searchFlag: true,
        isAuth: true,
        activeMenu: '/terminal/overview',
        parentTitle: [
          {
            title: 'app-terminal'
          },
          {
            title: 'app-terminal-overview',
            path: '/terminal/overview'
          }
        ]
      }
    },
    {
      path: '/terminal/status/:id',
      name: 'TerminalStatus',
      component: () =>
        import('@/views/terminal/terminal-status/terminal-status.vue'),
      meta: {
        title: 'terminal-status',
        searchFlag: true,
        isAuth: true,
        activeMenu: '/terminal/overview',
        parentTitle: [
          {
            title: 'app-terminal'
          },
          {
            title: 'app-terminal-overview',
            path: '/terminal/overview'
          }
        ]
      }
    },
    {
      path: '/terminal/new',
      name: 'TerminalNew',
      component: () =>
        import('@/views/terminal/terminal-handle/terminal-handle.vue'),
      meta: {
        title: 'terminal-new',
        searchFlag: true,
        isAuth: true,
        activeMenu: '/terminal/overview',
        parentTitle: [
          {
            title: 'app-terminal'
          },
          {
            title: 'app-terminal-overview',
            path: '/terminal/overview'
          }
        ]
      }
    },
    {
      path: '/terminal/edit/:id',
      name: 'TerminalEdit',
      component: () =>
        import('@/views/terminal/terminal-handle/terminal-handle.vue'),
      meta: {
        title: 'terminal-edit',
        searchFlag: true,
        isAuth: true,
        activeMenu: '/terminal/overview',
        parentTitle: [
          {
            title: 'app-terminal'
          },
          {
            title: 'app-terminal-overview',
            path: '/terminal/overview'
          }
        ]
      }
    },
    {
      path: '/terminal/view/:id',
      name: 'TerminalView',
      component: () =>
        import('@/views/terminal/terminal-handle/terminal-handle.vue'),
      meta: {
        title: 'terminal-view',
        searchFlag: true,
        isAuth: true,
        activeMenu: '/terminal/overview',
        parentTitle: [
          {
            title: 'app-terminal'
          },
          {
            title: 'app-terminal-overview',
            path: '/terminal/overview'
          }
        ]
      }
    },
    {
      path: '/terminal/new/import',
      name: 'TerminalImport',
      component: () =>
        import('@/views/terminal/terminal-list/terminal-import.vue'),
      meta: {
        title: 'terminal-import',
        searchFlag: true,
        isAuth: true,
        activeMenu: '/terminal/overview',
        parentTitle: [
          {
            title: 'app-terminal'
          },
          {
            title: 'app-terminal-overview',
            path: '/terminal/overview'
          }
        ]
      }
    },
    {
      path: '/terminal/new/param',
      name: 'TerminalImportParam',
      component: () =>
        import('@/views/terminal/terminal-list/terminal-import-param.vue'),
      meta: {
        title: 'terminal-import-param',
        searchFlag: true,
        isAuth: true,
        activeMenu: '/terminal/overview',
        parentTitle: [
          {
            title: 'app-terminal'
          },
          {
            title: 'app-terminal-overview',
            path: '/terminal/overview'
          }
        ]
      }
    },
    {
      path: '/terminal/batch',
      name: 'BatchOverview',
      component: () => import('@/views/terminal/batch/batch-list/index.vue'),
      meta: {
        title: 'terminal-batch',
        searchFlag: true,
        isAuth: true,
        parentTitle: [
          {
            title: 'app-terminal'
          }
        ]
      }
    },
    {
      path: '/terminal/batch/view/:id',
      name: 'BatchView',
      component: () => import('@/views/terminal/batch/batch-handle/index.vue'),
      meta: {
        title: 'batch-view',
        searchFlag: true,
        isAuth: true,
        activeMenu: '/terminal/batch',
        parentTitle: [
          {
            title: 'app-terminal'
          },
          {
            title: 'app-terminal-batch',
            path: '/terminal/batch'
          }
        ]
      }
    },
    {
      path: '/terminal/batch/edit/:id',
      name: 'BatchEdit',
      component: () => import('@/views/terminal/batch/batch-handle/index.vue'),
      meta: {
        title: 'batch-edit',
        searchFlag: true,
        isAuth: true,
        activeMenu: '/terminal/batch',
        parentTitle: [
          {
            title: 'app-terminal'
          },
          {
            title: 'app-terminal-batch',
            path: '/terminal/batch'
          }
        ]
      }
    },
    {
      path: '/terminal/batch/new',
      name: 'BatchNew',
      component: () => import('@/views/terminal/batch/batch-handle/index.vue'),
      meta: {
        title: 'batch-new',
        searchFlag: true,
        isAuth: true,
        activeMenu: '/terminal/batch',
        parentTitle: [
          {
            title: 'app-terminal'
          },
          {
            title: 'app-terminal-batch',
            path: '/terminal/batch'
          }
        ]
      }
    },
    {
      path: '/terminal/brand',
      name: 'BrandOverview',
      component: () => import('@/views/terminal/brand/brand-list/index.vue'),
      meta: {
        title: 'terminal-brand',
        searchFlag: true,
        isAuth: true,
        parentTitle: [
          {
            title: 'app-terminal'
          }
        ]
      }
    },
    {
      path: '/terminal/brand/new',
      name: 'BrandNew',
      component: () => import('@/views/terminal/brand/brand-handle/index.vue'),
      meta: {
        title: 'brand-new',
        searchFlag: true,
        isAuth: true,
        activeMenu: '/terminal/brand',
        parentTitle: [
          {
            title: 'app-terminal'
          },
          {
            title: 'app-terminal-brand',
            path: '/terminal/brand'
          }
        ]
      }
    },
    {
      path: '/terminal/brand/edit/:id',
      name: 'BrandEdit',
      component: () => import('@/views/terminal/brand/brand-handle/index.vue'),
      meta: {
        title: 'brand-edit',
        searchFlag: true,
        isAuth: true,
        activeMenu: '/terminal/brand',
        parentTitle: [
          {
            title: 'app-terminal'
          },
          {
            title: 'app-terminal-brand',
            path: '/terminal/brand'
          }
        ]
      }
    },
    {
      path: '/terminal/brand/view/:id',
      name: 'BrandView',
      component: () => import('@/views/terminal/brand/brand-handle/index.vue'),
      meta: {
        title: 'brand-view',
        searchFlag: true,
        isAuth: true,
        activeMenu: '/terminal/brand',
        parentTitle: [
          {
            title: 'app-terminal'
          },
          {
            title: 'app-terminal-brand',
            path: '/terminal/brand'
          }
        ]
      }
    },
    {
      path: '/terminal/model',
      name: 'Models',
      component: () => import('@/views/terminal/model/model-list/models.vue'),
      meta: {
        title: 'model-list',
        searchFlag: true,
        isAuth: true,
        parentTitle: [
          {
            title: 'app-terminal'
          }
        ]
      }
    },
    {
      path: '/model/new',
      name: 'ModelNew',
      component: () =>
        import('@/views/terminal/model/model-handle/model-handle.vue'),
      meta: {
        title: 'model-new',
        searchFlag: true,
        isAuth: true,
        activeMenu: '/terminal/model',
        parentTitle: [
          {
            title: 'app-terminal'
          },
          {
            title: 'app-terminal-model',
            path: '/terminal/model'
          }
        ]
      }
    },
    {
      path: '/model/view/:id',
      name: 'ModelView',
      component: () =>
        import('@/views/terminal/model/model-handle/model-handle.vue'),
      meta: {
        title: 'model-view',
        searchFlag: true,
        isAuth: true,
        activeMenu: '/terminal/model',
        parentTitle: [
          {
            title: 'app-terminal'
          },
          {
            title: 'app-terminal-model',
            path: '/terminal/model'
          }
        ]
      }
    },
    {
      path: '/model/edit/:id',
      name: 'ModelEdit',
      component: () =>
        import('@/views/terminal/model/model-handle/model-handle.vue'),
      meta: {
        title: 'model-edit',
        searchFlag: true,
        isAuth: true,
        activeMenu: '/terminal/model',
        parentTitle: [
          {
            title: 'app-terminal'
          },
          {
            title: 'app-terminal-model',
            path: '/terminal/model'
          }
        ]
      }
    },
    {
      path: '/terminal/remote',
      name: 'remoteOverview',
      component: () => import('@/views/terminal/remote/remote-list/index.vue'),
      meta: {
        title: 'terminal-remote',
        searchFlag: true,
        isAuth: true,
        parentTitle: [
          {
            title: 'app-terminal'
          }
        ]
      }
    },
    {
      path: '/terminal/remote/edit/:id',
      name: 'TerminalRemoteEdit',
      component: () =>
        import('@/views/terminal/remote/remote-handle/index.vue'),
      meta: {
        title: 'terminal-remote-edit',
        searchFlag: true,
        isAuth: true,
        activeMenu: '/terminal/remote',
        parentTitle: [
          {
            title: 'app-terminal'
          },
          {
            title: 'app-terminal-remote',
            path: '/terminal/remote'
          }
        ]
      }
    },
    {
      path: '/terminal/remote/view/:id',
      name: 'TerminalRemoteView',
      component: () =>
        import('@/views/terminal/remote/remote-handle/index.vue'),
      meta: {
        title: 'terminal-remote-view',
        searchFlag: true,
        isAuth: true,
        activeMenu: '/terminal/remote',
        parentTitle: [
          {
            title: 'app-terminal'
          },
          {
            title: 'app-terminal-remote',
            path: '/terminal/remote'
          }
        ]
      }
    },
    {
      path: '/terminal/remote/new',
      name: 'TerminalRemoteNew',
      component: () =>
        import('@/views/terminal/remote/remote-handle/index.vue'),
      meta: {
        title: 'terminal-remote-new',
        searchFlag: true,
        isAuth: true,
        activeMenu: '/terminal/remote',
        parentTitle: [
          {
            title: 'app-terminal'
          },
          {
            title: 'app-terminal-remote',
            path: '/terminal/remote'
          }
        ]
      }
    }
  ]
}
