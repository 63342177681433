<template>
  <div class="app-main">
    <el-config-provider :locale="locale">
      <router-view v-slot="{ Component, route }">
        <transition name="fade-transform" mode="out-in">
          <!-- <keep-alive :max="MAX_TAGS_VIEW_SIZE"> -->
          <div :key="route.path">
            <component :is="Component"></component>
          </div>
          <!-- </keep-alive> -->
        </transition>
      </router-view>
    </el-config-provider>
  </div>
</template>

<script setup>
import { ElNotification } from 'element-plus'
import { watch, onMounted, onBeforeUnmount, ref, getCurrentInstance } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { isTags } from '@/utils/tag'
import { generateTitle, watchSwitchLang } from '@/utils/i18n'
// import { getCookie } from '@/utils/system'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import en from 'element-plus/lib/locale/lang/en'
import { initialStore } from '../../store/index'
import i18n from '@/i18n'
import { setItem, getItem } from '@/utils/storage'
import { HASPOPUP } from '@/utils/constant/index'

const route = useRoute()
const store = useStore()
const { t } = i18n.global

document.clickBtn = function clickBtn() {
  notification.value.close()
}
initialStore()

const locale = ref(null)
const { appContext } = getCurrentInstance()
const bus = appContext.config.globalProperties.$bus

const handleGlobalLogout = appContext.config.globalProperties.$handleLogout

let timer = null
onMounted(() => {
  // setInterval 设置定时器 轮询查询session is timeout or not
  timer = setInterval(async () => {
    // const sid = getCookie('sid')
    // const res = await store.dispatch('user/handleIsSessionTimeout', sid)
    const res = await store.dispatch('user/handleIsSessionTimeout')
    // 处理超时回到登录页的请求
    if (res.data.code !== 200) {
      handleGlobalLogout(false)
    }
  }, 30000)
})

watch(
  () => store.getters.language,
  () => {
    locale.value = store.getters.language === 'en' ? en : zhCn
  },
  {
    immediate: true
  }
)

/**
 * 生成Tag时的标题
 */
const getTitle = (route) => {
  let title = ''
  if (!route.meta) {
    // 处理无标题的路由
    const titleArr = route.path.split('/')
    title = titleArr[titleArr.length - 1]
  } else {
    title = generateTitle(route.meta.title)
  }
  return title
}

// 表示是否弹出一次以上

// // 这两个先写死 后续再让后端写个接口返回
// const noticeMessgae = t('profile.have-unread-message', { num: 2 })
// const unRead = ref(true)

// if (!fisrtMorePopUp && unRead.value) {
//   setItem(HASPOPUP, true)
//   notification.value = ElNotification({
//     title: t('profile.notification'),
//     duration: 0,
//     dangerouslyUseHTMLString: true,
//     offset: 60,
//     message: `<div><span>${noticeMessgae}</span><a class="notice-view__btn" onclick="clickBtn()"  href='/#/system/message'>${t(
//       'general.view'
//     )}</a></div>`
//   })
// }

const notification = ref('')
const hasPopup = getItem(HASPOPUP)

const getUserPassworsExpiredDay = async () => {
  const day = await store.dispatch('user/getPasswordExpiryDay')
  // 已弹出过则不需要再弹窗
  if (day > 0 && day <= 10 && !hasPopup) {
    setItem(HASPOPUP, true)
    notification.value = ElNotification({
      title: t('general.tips'),
      duration: 0,
      dangerouslyUseHTMLString: true,
      offset: 60,
      message: `<div><span>The password expires in ${day} days.</span><div style="margin-bottom: 10px">Do you need to change the password?</div><a class="change-password__btn" onclick="clickBtn()"  href='/#/system/profile/changePassword'>${t(
        'general.router-change-password'
      )}</a></div>`
    })

    store.commit('app/setPasswordExpiryNotification', notification.value)
  }
}

getUserPassworsExpiredDay()
/**
 * 监听路由的变化
 */

watch(
  route,
  (to, from) => {
    if (!isTags(to.path)) return
    const { fullPath, meta, name, params, path, query } = to
    store.commit('app/addTagsViewList', {
      fullPath,
      meta,
      name,
      params,
      path,
      query,
      title: getTitle(to)
    })
    bus.emit('scrollToActiveTag')
  },
  {
    immediate: true
  }
)

/****
 * 国际化处理Tag
 */
watchSwitchLang(() => {
  store.getters.tagsViewList.forEach((route, index) => {
    store.commit('app/changeTagsView', {
      index,
      tag: {
        ...route,
        title: getTitle(route)
      }
    })
  })
})

// 清除定时器
onBeforeUnmount(() => {
  clearInterval(timer)
  timer = null
})
</script>

<style>
.change-password__btn {
  align-items: center;
  background-color: #fff;
  border-radius: 6px;
  border-style: none;
  box-shadow: rgba(0, 0, 0, 0.2) 0 3px 5px -1px,
    rgba(0, 0, 0, 0.14) 0 6px 20px 0, rgba(0, 0, 0, 0.1) 0 1px 18px 0;
  color: #3c4043;
  cursor: pointer;
  font-size: 14px;
  height: 24px;
  padding: 3px 5px;
}

.change-password__btn:hover {
  background: #f6f9fe;
  color: #174ea6;
}

.change-password__btn:active {
  box-shadow: 0 4px 4px 0 rgb(60 64 67 / 30%),
    0 8px 12px 6px rgb(60 64 67 / 15%);
  outline: none;
}
</style>
<style lang="scss" scoped>
.app-main {
  // background-color: #fafafa;
  min-height: calc(100vh - 50px - 43px);
  width: 100%;
  position: relative;
  overflow: hidden;
  padding: 104px 20px 80px 20px;
  box-sizing: border-box;
}
</style>
