import request from '../utils/request'

export const getMonitorStatusList = (data) => {
  return request({
    url: '/rest/monitor/terminal/status',
    data: data,
    method: 'post'
  })
}

export const getMonitorStatusDetailBySn = (sn) => {
  return request({
    url: `/rest/terminalStatus/get/${sn}`,
    method: 'get'
  })
}

export const getMonitorOtaList = (data) => {
  return request({
    url: '/rest/monitor/ota/push',
    data: data,
    method: 'post'
  })
}

export const getMonitorAppList = (data) => {
  return request({
    url: '/rest/monitor/app/push',
    data: data,
    method: 'post'
  })
}

export const getMonitorParamList = (data) => {
  return request({
    url: '/rest/monitor/param/push',
    data: data,
    method: 'post'
  })
}

export const getMonitorOtaDetailList = (data) => {
  return request({
    url: '/rest/monitor/ota/push/detail',
    data: data,
    method: 'post'
  })
}

export const getMonitorAppDetailList = (data) => {
  return request({
    url: '/rest/monitor/app/push/detail',
    data: data,
    method: 'post'
  })
}

export const getMonitorParamDetailList = (data) => {
  return request({
    url: '/rest/monitor/param/push/detail',
    data: data,
    method: 'post'
  })
}

export const getOnlineTerminals = (data) => {
  return request({
    url: '/rest/terminal/getPage',
    data: data,
    method: 'post'
  })
}

export const deleteTerminalOnline = (id) => {
  return request({
    url: `/rest/terminal/del/${id}`,
    method: 'delete'
  })
}

export const getBatchJobsList = (data) => {
  return request({
    url: `/rest/batch/jobs/monitorByPage/${data.currentPage}/${data.pageSize}`,
    data,
    method: 'post'
  })
}

export const getBatchJobsDetail = (id) => {
  return request({
    url: `/rest/batch/jobs/${id}`,
    method: 'get'
  })
}
export const getBatchJobsLogs = (data) => {
  return request({
    url: `/rest/batch/jobs/${data.id}/logs/${data.currentPage}/${data.pageCount}`,
    data,
    method: 'post'
  })
}

export const getInstalledApplicationList = (data) => {
  return request({
    url: `/rest/terminalApp/installed`,
    method: 'post',
    data
  })
}
