/****
 * axios二次封装
 * 2022-09-24
 */

import axios from 'axios'
import { ElMessage } from 'element-plus'
import { showLoading, hideLoading } from './axiosHelperLoading'
import qs from 'qs'
import i18n from '@/i18n'
// import { handleLogout } from '@/utils/globalHandler.js'

// 创建实例对象 添加全局配置
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  // withCredentials: true,
  timeout: 1000 * 60 * 5
})

// service.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
service.defaults.withCredentials = true

// 请求拦截器
service.interceptors.request.use((request) => {
  if (request.method.toLowerCase() === 'get') {
    if (request.url.indexOf('?') === -1) {
      request.url += '?'
    } else {
      request.url += '&'
    }
    request.url += Math.random()
  }
  return request
})

// 响应拦截器
service.interceptors.response.use(
  (response) => {
    // 响应拦截进来隐藏loading效果，此处采⽤延时处理是合并loading请求效果，避免多次请求loading关闭⼜开启
    setTimeout(() => {
      hideLoading()
    }, 200)
    if (response.status === 200) return response

    // 验证session 超时
    // if (
    //   response.data &&
    //   response.data.resultCode === '99' &&
    //   response.data.resultMessage !== 'random code error'
    // ) {
    //   ElMessage.error(i18n.global.t('code.session-timeout'))
    //   handleLogout(false)
    // }
    // 判断status 302
    // if (response.status === 200) {
    //   return response
    // } else if (response.status === 302 || response.data.includes('html')) {
    //   // 处理后端重定向问题,重定向导致res.data数据是html代码(这里由于重定向但是状态码还是200所以特殊判断)
    //   handleLogout(false)
    // } else {
    //   const message = i18n.global.t('code.error')
    //   Promise.reject(message)
    // }
  },
  (error) => {
    // 响应拦截进来隐藏loading效果，此处采⽤延时处理是合并loading请求效果，避免多次请求loading关闭⼜开启
    setTimeout(() => {
      hideLoading()
    }, 200)
    // 接收到异常响应的处理开始
    if (error && error.response) {
      // 1.公共错误处理
      // 2.根据响应码具体处理
      const errorCodeMessages = {
        302: i18n.global.t('code.connect-error'),
        400: i18n.global.t('code.400'),
        401: i18n.global.t('code.401'),
        403: i18n.global.t('code.403'),
        404: i18n.global.t('code.404'),
        405: i18n.global.t('code.405'),
        408: i18n.global.t('code.408'),
        500: i18n.global.t('code.500'),
        501: i18n.global.t('code.501'),
        502: i18n.global.t('code.502'),
        503: i18n.global.t('code.503'),
        504: i18n.global.t('code.504'),
        505: i18n.global.t('code.505')
      }
      if (errorCodeMessages[error.response?.data?.code]) {
        error.message = errorCodeMessages[error.response?.data?.code]
        if (error.response.status === 500) {
          // handleLogout(false);
          return Promise.reject(new Error('Server Error'))
        }
      } else {
        error.message = `${i18n.global.t('code.connect-error')}`
      }
    } else {
      // 超时处理
      if (qs.stringify(error).includes('timeout')) {
        error.message = i18n.global.t('code.time-out')
      }
    }
    ElMessage.error(error.message)
    // 处理结束
    return Promise.reject(error.message)
  }
)

function request(options) {
  // 默认为get请求
  options.method = options.method || 'get'
  if (options.method.toLowerCase() === 'get') {
    options.params = options.data
  }
  if (options.otherOptions && options.otherOptions.headers) {
    service.defaults.headers = options.otherOptions.headers
  } else {
    service.defaults.headers = {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }
  }
  // 为避免页面频繁显示loading，当为get请求或者url含get字符(由于后端不规范很多get请求也设为post方法所以做此兼容)则不显示loading
  if (!(options.method === 'get' || options.url.includes('get'))) {
    showLoading()
  }
  return service(options)
}

;['get', 'post', 'put', 'delete', 'patch'].forEach((item) => {
  request[item] = (url, data, options) => {
    return request({
      url,
      data,
      method: item,
      ...options
    })
  }
})

export default request
