import {
  getTenantLists,
  handleNewTenantAction,
  handleUpdateTenantAction,
  handleDeleteTenant,
  getTenantById,
  getCustomerLists,
  getCustomerById,
  getCustomerTypeList,
  getCustomerStatusList,
  handleCustomerSubmit,
  deleteCustomerById,
  handleCustomerUpdate,
  getMerchantListByPage,
  getMerchantById,
  getMerchantTypeList,
  getMerchantStatusList,
  handleMerchantSubmit,
  deleteMerchantById,
  handleMerchantUpdate,
  getTenantTreeData,
  getSubTenantListById,
  getTenantMapData,
  handleDisableCustomer,
  handleEnableCustomer,
  handleDisableMerchant,
  handleEnableMerchant
} from '../../service/tenant'

export default {
  namespaced: true,
  state: () => ({
    tenantCount: 0,
    tenantList: [],
    customersCount: 0,
    customersList: [],
    merchantsCount: 0,
    merchantsList: [],
    selectedTenantList: [],
    tenantDataMap: {}
  }),
  getters: {
    pageListData(state) {
      return (pageName) => {
        return state[`${pageName}List`]
      }
    },
    pageListCount(state) {
      return (pageName) => {
        return state[`${pageName}Count`]
      }
    }
  },
  mutations: {
    setSelectedTenantList(state, tenantList) {
      state.selectedTenantList = tenantList
    },
    setTenantCount(state, tenantCount) {
      state.tenantCount = tenantCount
    },
    setTenantList(state, tenantList) {
      state.tenantList = tenantList
    },
    setCustomersCount(state, customersCount) {
      state.customersCount = customersCount
    },
    setCustomersList(state, customersList) {
      state.customersList = customersList
    },
    setMerchantsCount(state, merchantsCount) {
      state.merchantsCount = merchantsCount
    },
    setMerchantsList(state, merchantsList) {
      state.merchantsList = merchantsList
    },
    setTenantDataMap(state, tenantDataMap) {
      state.tenantDataMap = tenantDataMap
    }
  },
  actions: {
    async getTenantLists(context, payload) {
      const res = await getTenantLists(payload)
      this.commit('tenant/setTenantList', res?.data?.data.records || [])
      this.commit('tenant/setTenantCount', res?.data?.data.total || 0)
      return res
    },
    async handleNewTenantAction(context, payload) {
      const res = await handleNewTenantAction(payload)
      return res
    },
    async handleUpdateTenantAction(context, payload) {
      const res = await handleUpdateTenantAction(payload)
      return res
    },
    async handleDeleteTenant(context, payload) {
      const res = await handleDeleteTenant(payload)
      return res
    },
    async getTenantById(context, payload) {
      const res = await getTenantById(payload)
      return res?.data?.data || {}
    },
    async getCustomerListData(context, payload) {
      const res = await getCustomerLists(payload)
      this.commit('tenant/setCustomersList', res?.data?.data?.records || [])
      this.commit('tenant/setCustomersCount', res?.data?.data?.total || 0)
      return res
    },
    async getCustomerTypeList(context, payload = {}) {
      const res = await getCustomerTypeList(payload)
      const result = Object.keys(res.data).map((i) => {
        return {
          id: i,
          name: res.data[i]
        }
      })
      return result
    },
    async getCustomerStatusList(context, payload = {}) {
      const res2 = await getCustomerStatusList(payload)
      const result2 = Object.keys(res2.data).map((i) => {
        return {
          id: i,
          name: res2.data[i]
        }
      })
      return result2
    },
    async handleCustomerAction(context, payload) {
      const res = await handleCustomerSubmit(payload)
      return res
    },
    async handleUpdateCustomer(context, payload) {
      const res = await handleCustomerUpdate(payload)
      return res
    },
    async handleGetCustomer(context, payload) {
      const res = await getCustomerById(payload)
      return res?.data?.data || {}
    },
    async handleDeleteCustomer({ dispatch }, payload) {
      const res = await deleteCustomerById(payload)
      return res
    },
    async getMerchantListByPage(context, payload) {
      const res = await getMerchantListByPage(payload)
      this.commit('tenant/setMerchantsList', res?.data?.data?.records || [])
      this.commit('tenant/setMerchantsCount', res?.data?.data?.total || 0)
      return res
    },
    async getMerchantTypeList(context, payload = {}) {
      const res = await getMerchantTypeList(payload)
      const result = Object.keys(res.data).map((i) => {
        return {
          id: i,
          name: res.data[i]
        }
      })
      return result
    },
    async getMerchantStatusList(context, payload = {}) {
      const res = await getMerchantStatusList(payload)

      const result = Object.keys(res.data).map((i) => {
        return {
          id: i,
          name: res.data[i]
        }
      })
      return result
    },
    async handleMerchantAction(context, payload) {
      const res = await handleMerchantSubmit(payload)
      return res
    },
    async handleUpdateMerchant(context, payload) {
      const res = await handleMerchantUpdate(payload)
      return res
    },
    async handleGetMerchant(context, payload) {
      const res = await getMerchantById(payload)
      return res?.data?.data || {}
    },
    async handleDeleteMerchant({ dispatch }, payload) {
      const res = await deleteMerchantById(payload)
      return res
    },
    async getTenantTreeData(context, payload) {
      const res = await getTenantTreeData(payload)
      return res?.data?.data
    },
    async getSubTenantListById(context, payload) {
      const res = await getSubTenantListById(payload)
      return res?.data?.data
    },
    async getTenantMapData(context, payload) {
      const res = await getTenantMapData()
      console.log(res.data)
      this.commit('tenant/setTenantDataMap', res?.data?.data || {})
    },
    async handleDisableCustomer(context, payload) {
      const res = await handleDisableCustomer(payload)
      return res
    },
    async handleEnableCustomer(context, payload) {
      const res = await handleEnableCustomer(payload)
      return res
    },
    async handleDisableMerchant(context, payload) {
      const res = await handleDisableMerchant(payload)
      return res
    },
    async handleEnableMerchant(context, payload) {
      const res = await handleEnableMerchant(payload)
      return res
    }
  }
}
