import layout from '@/Layout/index.vue'

export default {
  path: '/home',
  component: layout,
  redirect: '/home',
  meta: {
    title: 'home'
  },
  children: [
    {
      path: '/monitor/status',
      name: 'MonitorStatus',
      component: () => import('@/views/monitor/MonitorStatus/index.vue'),
      meta: {
        title: 'monitor-status',
        isAuth: true,
        searchFlag: true,
        parentTitle: [
          {
            title: 'app-monitor'
          }
        ]
      }
    },
    {
      path: '/monitor/status/detail/:sn',
      name: 'MonitorStatusDetail',
      component: () => import('@/views/monitor/MonitorStatusDetail/index.vue'),
      meta: {
        title: 'monitor-status-detail',
        isAuth: true,
        searchFlag: true,
        activeMenu: '/monitor/status',
        parentTitle: [
          {
            title: 'app-monitor'
          },
          {
            title: 'monitor-status',
            path: '/monitor/status'
          }
        ]
      }
    },
    {
      path: '/monitor/status/application/:sn',
      name: 'MonitorStatusApplicationsDetail',
      component: () =>
        import('@/views/monitor/MonitorStatusApplicationDetail/index.vue'),
      meta: {
        title: 'monitor-status-applications-detail',
        isAuth: true,
        searchFlag: true,
        activeMenu: '/monitor/status',
        parentTitle: [
          {
            title: 'app-monitor'
          },
          {
            title: 'monitor-status',
            path: '/monitor/status'
          }
        ]
      }
    },
    {
      path: '/monitor/app',
      name: 'MonitorApp',
      component: () => import('@/views/monitor/MonitorApp/index.vue'),
      meta: {
        title: 'monitor-app',
        isAuth: true,
        searchFlag: true,
        parentTitle: [
          {
            title: 'app-monitor'
          }
        ]
      }
    },
    {
      path: '/monitor/app/detail/:id',
      name: 'MonitorAppDetail',
      component: () => import('@/views/monitor/MonitorAppDetail/index.vue'),
      meta: {
        title: 'monitor-app-detail',
        isAuth: true,
        searchFlag: true,
        activeMenu: '/monitor/app',
        parentTitle: [
          {
            title: 'app-monitor'
          },
          {
            title: 'monitor-app',
            path: '/monitor/app'
          }
        ]
      }
    },
    {
      path: '/monitor/param',
      name: 'MonitorParam',
      component: () => import('@/views/monitor/MonitorParam/index.vue'),
      meta: {
        title: 'monitor-param',
        isAuth: true,
        searchFlag: true,
        parentTitle: [
          {
            title: 'app-monitor'
          }
        ]
      }
    },
    {
      path: '/monitor/param/detail/:id',
      name: 'MonitorParamDetail',
      component: () => import('@/views/monitor/MonitorParamDetail/index.vue'),
      meta: {
        title: 'monitor-param-detail',
        isAuth: true,
        searchFlag: true,
        activeMenu: '/monitor/param',
        parentTitle: [
          {
            title: 'app-monitor'
          },
          {
            title: 'monitor-param',
            path: '/monitor/param'
          }
        ]
      }
    },
    {
      path: '/monitor/ota',
      name: 'MonitorOta',
      component: () => import('@/views/monitor/MonitorOta/index.vue'),
      meta: {
        title: 'monitor-ota',
        isAuth: true,
        searchFlag: true,
        parentTitle: [
          {
            title: 'app-monitor'
          }
        ]
      }
    },
    {
      path: '/monitor/ota/detail/:id',
      name: 'MonitorOtaDetail',
      component: () => import('@/views/monitor/MonitorOtaDetail/index.vue'),
      meta: {
        title: 'monitor-ota-detail',
        isAuth: true,
        searchFlag: true,
        activeMenu: '/monitor/ota',
        parentTitle: [
          {
            title: 'app-monitor'
          },
          {
            title: 'monitor-ota',
            path: '/monitor/ota'
          }
        ]
      }
    }
    // {
    //   path: '/monitor/online',
    //   name: 'OnlineTerminal',
    //   component: () =>
    //     import('@/views/monitor/terminalOnline/terminal-online.vue'),
    //   meta: {
    //     title: 'online-terminal',
    //     isAuth: true,
    //     searchFlag: true,
    //     parentTitle: [
    //       {
    //         title: 'app-monitor'
    //       }
    //     ]
    //   }
    // }
    // {
    //   path: '/monitor/batch',
    //   name: 'BatchJob',
    //   component: () =>
    //     import('@/views/monitor/batchJobs/batchJobs-list/index.vue'),
    //   meta: {
    //     title: 'batch-job',
    //     isAuth: true,
    //     searchFlag: true,
    //     parentTitle: [
    //       {
    //         title: 'app-monitor'
    //       }
    //     ]
    //   }
    // },
    // {
    //   path: '/monitor/batch-view/:id',
    //   name: 'BatchJobView',
    //   component: () =>
    //     import('@/views/monitor/batchJobs/batchJobs-view/index.vue'),
    //   meta: {
    //     title: 'batch-job-view',
    //     isAuth: true,
    //     activeMenu: '/monitor/batch',
    //     searchFlag: true,
    //     parentTitle: [
    //       {
    //         title: 'app-monitor'
    //       }
    //     ]
    //   }
    // }
  ]
}
