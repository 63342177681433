import request from '../utils/request'

export const getTenantLists = (data) => {
  return request({
    url: '/rest/tenant/getPage',
    data: data,
    method: 'post'
  })
}

export const handleNewTenantAction = (data) => {
  return request({
    url: '/rest/tenant/add',
    data,
    method: 'post'
  })
}

export const handleUpdateTenantAction = (data) => {
  return request({
    url: '/rest/tenant/edit',
    data,
    method: 'put'
  })
}

export const handleDeleteTenant = (id) => {
  return request({
    url: `/rest/tenant/del/${id}`,
    method: 'delete'
  })
}

export const getTenantById = (data) => {
  return request({
    url: `/rest/tenant/get/${data.id}`,
    // data: id,
    method: 'get'
  })
}

export const getCustomerLists = (data) => {
  return request({
    url: '/rest/customer/getPage',
    data: data,
    method: 'post'
  })
}

export const getCustomerTypeList = (data) => {
  return request({
    url: '/rest/customer/getType',
    data: data,
    method: 'get'
  })
}
export const getCustomerStatusList = (data) => {
  return request({
    url: '/rest/customer/getStatus',
    data: data,
    method: 'get'
  })
}
export const handleCustomerSubmit = (data) => {
  return request({
    url: '/rest/customer/add',
    data: data,
    method: 'post'
  })
}

export const getCustomerById = (data) => {
  return request({
    url: `/rest/customer/get/${data.id}`,
    method: 'get'
  })
}
export const deleteCustomerById = (id) => {
  return request({
    url: `/rest/customer/del/${id}`,
    method: 'delete'
  })
}

export const handleCustomerUpdate = (data) => {
  return request({
    url: '/rest/customer/edit',
    data: data,
    method: 'put'
  })
}

export const getMerchantListByPage = (data) => {
  return request({
    url: '/rest/merchant/getPage',
    data: data,
    method: 'post'
  })
}

export const getMerchantTypeList = (data) => {
  return request({
    url: '/rest/merchant/getType',
    data: data,
    method: 'get'
  })
}
export const getMerchantStatusList = (data) => {
  return request({
    url: '/rest/merchant/getStatus',
    data: data,
    method: 'get'
  })
}
export const handleMerchantSubmit = (data) => {
  return request({
    url: '/rest/merchant/add',
    data: data,
    method: 'post'
  })
}

export const getMerchantById = (data) => {
  return request({
    url: `/rest/merchant/get/${data.id}`,
    method: 'get'
  })
}
export const deleteMerchantById = (id) => {
  return request({
    url: `/rest/merchant/del/${id}`,
    method: 'delete'
  })
}

export const handleMerchantUpdate = (data) => {
  return request({
    url: '/rest/merchant/edit',
    data: data,
    method: 'put'
  })
}

export const getTenantTreeData = () => {
  return request({
    url: '/rest/tenant/getTenantTier',
    method: 'get'
  })
}

export const getSubTenantListById = (id) => {
  return request({
    url: `/rest/tenant/getTenantTier?tenantId=${id}`,
    method: 'get'
  })
}

export const getTenantMapData = () => {
  return request({
    url: '/rest/tenant/option',
    method: 'get'
  })
}

export const handleDisableCustomer = (id) => {
  return request({
    url: `/rest/customer/disable/${id}`,
    method: 'put'
  })
}

export const handleEnableCustomer = (id) => {
  return request({
    url: `/rest/customer/enable/${id}`,
    method: 'put'
  })
}

export const handleDisableMerchant = (id) => {
  return request({
    url: `/rest/merchant/disable/${id}`,
    method: 'put'
  })
}

export const handleEnableMerchant = (id) => {
  return request({
    url: `/rest/merchant/enable/${id}`,
    method: 'put'
  })
}
