import request from '../utils/request'

export const getTerminalLists = (data) => {
  return request({
    url: '/rest/terminal/getPage',
    data: data,
    method: 'post'
  })
}

export const handleEnableTerminal = (id) => {
  return request({
    url: `/rest/terminal/enable/${id}`,
    method: 'put'
  })
}

export const handleDisableTerminal = (id) => {
  return request({
    url: `/rest/terminal/disable/${id}`,
    method: 'put'
  })
}

export const deleteTerminalById = (id) => {
  return request({
    url: `/rest/terminal/del/${id}`,
    method: 'delete'
  })
}
export const deleteMultiTerminal = (data) => {
  return request({
    url: `/rest/terminal/del`,
    data: data,
    method: 'delete'
  })
}
export const getTerminalStatusList = (data) => {
  return request({
    url: '/rest/terminal/getStatus',
    data: data,
    method: 'get'
  })
}
export const getTerminalById = (data) => {
  return request({
    url: `/rest/terminal/get/${data.id}`,
    method: 'get'
  })
}
export const getModelList = (data = {}) => {
  return request({
    url: `/rest/model/get`,
    data: data,
    method: 'post'
  })
}
export const getModelListByPage = (data) => {
  return request({
    url: `/rest/terminalModel/getPage`,
    data: data,
    method: 'post'
  })
}
export const handleTerminalSubmit = (data) => {
  return request({
    url: '/rest/terminal/add',
    data: data,
    method: 'post'
  })
}

export const handleUpdateModel = (data) => {
  return request({
    url: '/rest/model/edit',
    data: data,
    method: 'put'
  })
}

export const handleUpdateTerminal = (data) => {
  return request({
    url: '/rest/terminal/edit',
    data: data,
    method: 'put'
  })
}

export const getTerminalStatus = (data) => {
  return request({
    url: '/rest/terminal/status/get/page/' + data.terminalId,
    data: data,
    method: 'post'
  })
}

export const getCurrentTerminalRemoteParams = (terminalId) => {
  return request({
    url: `/rest/terminal/remote/get/valid/${terminalId}`,
    method: 'get'
  })
}

export const startRemoteClient = (data) => {
  return request({
    url: '/rest/terminal/remote/start',
    data: data,
    method: 'post'
  })
}

export const endRemoteClient = (terminalId) => {
  return request({
    url: `/rest/terminal/remote/end/${terminalId}`,
    method: 'get'
  })
}

export const getCurrentTerminalSession = (terminalId) => {
  console.log(terminalId)
  return request({
    url: `/rest/terminal/remote/get/status/${terminalId}`,
    method: 'get'
  })
}

export const acceptTerminalApplication = (data) => {
  return request({
    url: '/rest/terminal/remote/session/accept',
    method: 'post',
    data: data
  })
}

export const connectRemoteClient = (data) => {
  return request({
    url: '/rest/terminal/remote/session/connect',
    method: 'post',
    data: data
  })
}

export const endRemoteClientConnection = (terminalId) => {
  return request({
    url: `/rest/terminal/remote/end/${terminalId}`
  })
}
export const getBatchLists = (data) => {
  return request({
    url: '/rest/terminal/batch/getPage',
    method: 'post',
    data
  })
}

export const getBrandLists = (data) => {
  return request({
    url: '/rest/terminal/brand/getPage',
    method: 'post',
    data
  })
}

// TODO
export const getRemoteLists = (data) => {
  return request({
    url: '/rest/terminalRemote/getPage',
    data,
    method: 'post'
  })
}

export const handleBatchAdd = (data) => {
  return request({
    url: '/rest/terminal/batch/add',
    method: 'post',
    data
  })
}

export const getCurrentBatch = (id) => {
  return request({
    url: `/rest/terminal/batch/get/${id}`,
    method: 'get'
  })
}

export const updateCurrentBatch = (data) => {
  return request({
    url: '/rest/terminal/batch/edit',
    method: 'put',
    data
  })
}

export const deleteBatchById = (id) => {
  return request({
    url: `/rest/terminal/batch/del/${id}`,
    method: 'delete'
  })
}

export const handleBrandAdd = (data) => {
  return request({
    url: '/rest/terminal/brand/add',
    method: 'post',
    data
  })
}

export const deleteBrandById = (id) => {
  return request({
    url: `/rest/terminal/brand/del/${id}`,
    method: 'delete'
  })
}

export const updateCurrentBrand = (data) => {
  return request({
    url: '/rest/terminal/brand/edit',
    method: 'put',
    data
  })
}

export const getCurrentBrand = (id) => {
  return request({
    url: `/rest/terminal/brand/get/${id}`,
    method: 'get'
  })
}

export const handleModelAdd = (data) => {
  return request({
    url: '/rest/terminalModel/add',
    method: 'post',
    data
  })
}

export const updateCurrentModel = (data) => {
  return request({
    url: '/rest/terminalModel/edit',
    method: 'put',
    data
  })
}

export const getCurrentModel = (id) => {
  return request({
    url: `/rest/terminalModel/get/${id}`,
    method: 'get'
  })
}

export const deleteModelById = (id) => {
  return request({
    url: `/rest/terminalModel/del/${id}`,
    method: 'delete'
  })
}

export const terminalPushParam = (data) => {
  return request({
    url: '/rest/terminal/pushParam',
    method: 'post',
    data
  })
}
