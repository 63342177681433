import request from '../utils/request'

export const getParamLists = (data) => {
  return request({
    url: '/rest/terminalParam/getPage',
    data: data,
    method: 'post'
  })
}

export const getParamTypeList = (data) => {
  return request({
    url: '/rest/param/getType',
    data: data,
    method: 'get'
  })
}
export const getParamStatusList = (data) => {
  return request({
    url: '/rest/param/getStatus',
    data: data,
    method: 'get'
  })
}
export const getParamValueTypeList = (data) => {
  return request({
    url: '/rest/param/getValueType',
    data: data,
    method: 'get'
  })
}
export const handleAddParamSubmit = (data) => {
  return request({
    url: '/rest/terminalParam/add',
    data: data,
    method: 'post'
  })
}

export const handleUpdateParamSubmit = (data) => {
  return request({
    url: '/rest/terminalParam/edit',
    data: data,
    method: 'put'
  })
}

export const getParamById = (data) => {
  return request({
    url: `/rest/terminalParam/get/${data.id}`,
    // data: id,
    method: 'get'
  })
}

export const deleteParamById = (id) => {
  return request({
    url: `/rest/terminalParam/del/${id}`,
    method: 'delete'
  })
}

export const deleteSelectParam = (data) => {
  return request({
    url: `/rest/terminalParam/del`,
    data: data,
    method: 'delete'
  })
}

export const getAIDLists = (data) => {
  return request({
    url: '/rest/terminalParamAid/getPage',
    data: data,
    method: 'post'
  })
}

export const getAIDById = (data) => {
  return request({
    url: `/rest/terminalParamAid/get/${data.id}`,
    method: 'get'
  })
}

export const deleteAIDById = (id) => {
  return request({
    url: `/rest/terminalParamAid/del/${id}`,
    method: 'delete'
  })
}

export const handleAddAID = (data) => {
  return request({
    url: '/rest/terminalParamAid/add',
    data: data,
    method: 'post'
  })
}

export const handleUpdateAID = (data) => {
  return request({
    url: '/rest/terminalParamAid/edit',
    data: data,
    method: 'put'
  })
}

export const getCapkLists = (data) => {
  return request({
    url: '/rest/terminalParamCapk/getPage',
    data: data,
    method: 'post'
  })
}

export const getCapkById = (data) => {
  return request({
    url: `/rest/terminalParamCapk/get/${data.id}`,
    method: 'get'
  })
}

export const deleteCapkById = (id) => {
  return request({
    url: `/rest/terminalParamCapk/del/${id}`,
    method: 'delete'
  })
}

export const handleAddCapk = (data) => {
  return request({
    url: '/rest/terminalParamCapk/add',
    data: data,
    method: 'post'
  })
}

export const handleUpdateCapk = (data) => {
  return request({
    url: '/rest/terminalParamCapk/edit',
    data: data,
    method: 'put'
  })
}

export const getTemplateList = (data) => {
  return request({
    url: '/rest/paramTemplate/getPage',
    data: data,
    method: 'post'
  })
}

export const getTemplateById = (data) => {
  return request({
    url: `/rest/paramTemplate/get/${data.id}`,
    method: 'get'
  })
}

export const deleteTemplateById = (id) => {
  return request({
    url: `/rest/paramTemplate/del/${id}`,
    method: 'delete'
  })
}

export const handleAddTemplate = (data) => {
  return request({
    url: '/rest/paramTemplate/add',
    data: data,
    method: 'post'
  })
}

export const handleUpdateTemplate = (data) => {
  return request({
    url: '/rest/paramTemplate/edit',
    data: data,
    method: 'put'
  })
}

export const getTemplateGroupList = (data) => {
  return request({
    url: '/rest/terminalParam/group/getPage',
    data: data,
    method: 'post'
  })
}

export const getTemplateGroupById = (data) => {
  return request({
    url: `/rest/terminalParam/group/get/${data.id}`,
    method: 'get'
  })
}

export const getPageForGroupList = (data) => {
  return request({
    url: '/rest/terminal/getAll',
    data: data,
    method: 'post'
  })
}

export const deleteTemplateGroupById = (id) => {
  return request({
    url: `/rest/terminalParam/group/del/${id}`,
    method: 'delete'
  })
}

export const handlePublishTemplateGroup = (id) => {
  return request({
    url: `/rest/terminalParam/groupSetting/push/${id}`,
    method: 'post'
  })
}

export const handleAddTemplateGroup = (data) => {
  return request({
    url: '/rest/terminalParam/group/add',
    data: data,
    method: 'post'
  })
}

export const handleUpdateTemplateGroup = (data) => {
  return request({
    url: '/rest/terminalParam/group/edit',
    data: data,
    method: 'put'
  })
}

export const getTemplateGroupSettingList = (data) => {
  return request({
    url: '/rest/terminalParam/groupSetting/getPage',
    data: data,
    method: 'post'
  })
}

export const getTemplateGroupSettingById = (data) => {
  return request({
    url: `/rest/terminalParam/groupSetting/get/${data.id}`,
    method: 'get'
  })
}

export const deleteTemplateGroupSettingById = (id) => {
  return request({
    url: `/rest/terminalParam/groupSetting/del/${id}`,
    method: 'delete'
  })
}

export const handleAddTemplateGroupSetting = (data) => {
  return request({
    url: '/rest/terminalParam/groupSetting/add',
    data: data,
    method: 'post'
  })
}

export const handleUpdateTemplateGroupSetting = (data) => {
  return request({
    url: '/rest/terminalParam/groupSetting/edit',
    data: data,
    method: 'put'
  })
}

// 获取卡片列表
export const getCardLists = (data) => {
  return request({
    url: `/rest/terminalCard/getPage`,
    data,
    method: 'post'
  })
}

// 添加卡片
export const handleAddCardSubmit = (data) => {
  return request({
    url: '/rest/terminalCard/add',
    data,
    method: 'post'
  })
}

// 查看卡片详情
export const getCardListByID = (data) => {
  return request({
    url: `/rest/terminalCard/get/${data.id}`,
    method: 'get'
  })
}

// 删除卡片
export const deleteCard = (id) => {
  return request({
    url: `/rest/terminalCard/del/${id}`,
    method: 'delete'
  })
}

// 编辑卡片
export const handleEditCardSubmit = (data) => {
  return request({
    url: `/rest/terminalCard/edit`,
    data,
    method: 'put'
  })
}

// 获取卡Bin列表
export const getCardBinLists = (data) => {
  return request({
    url: `/rest/terminalCardBin/getPage`,
    data,
    method: 'post'
  })
}

// 添加卡bin
export const handleAddCardBinSubmit = (data) => {
  return request({
    url: '/rest/terminalCardBin/add',
    data,
    method: 'post'
  })
}

// 查看卡Bin详情
export const getCardBinListByID = (data) => {
  return request({
    url: `/rest/terminalCardBin/get/${data.id}`,
    method: 'get'
  })
}

// 编辑卡Bin
export const handleEditCardBinSubmit = (data) => {
  return request({
    url: `/rest/terminalCardBin/edit`,
    data,
    method: 'put'
  })
}

// 删除卡Bin
export const deleteCardBin = (id) => {
  return request({
    url: `/rest/terminalCardBin/del/${id}`,
    method: 'delete'
  })
}
// 所有启用禁用
// 卡BIN禁用启用
export const handleTerminalCardBinDisableAction = (id) => {
  return request({
    url: `/rest/terminalCardBin/disable/${id}`,
    method: 'put'
  })
}
export const handleTerminalCardBinEnableAction = (id) => {
  return request({
    url: `/rest/terminalCardBin/enable/${id}`,
    method: 'put'
  })
}

// 卡号禁用启用
export const handleTerminalCardDisableAction = (id) => {
  return request({
    url: `/rest/terminalCard/disable/${id}`,
    method: 'put'
  })
}
export const handleTerminalCardEnableAction = (id) => {
  return request({
    url: `/rest/terminalCard/enable/${id}`,
    method: 'put'
  })
}

// Aid参数禁用启用
export const handleTerminalParamAidDisableAction = (id) => {
  return request({
    url: `/rest/terminalParamAid/disable/${id}`,
    method: 'put'
  })
}
export const handleTerminalParamAidEnableAction = (id) => {
  return request({
    url: `/rest/terminalParamAid/enable/${id}`,
    method: 'put'
  })
}

// Capk参数禁用启用
export const handleTerminalParamCapkDisableAction = (id) => {
  return request({
    url: `/rest/terminalParamCapk/disable/${id}`,
    method: 'put'
  })
}
export const handleTerminalParamCapkEnableAction = (id) => {
  return request({
    url: `/rest/terminalParamCapk/enable/${id}`,
    method: 'put'
  })
}

// 参数禁用启用
export const handleTerminalParamDisableAction = (id) => {
  return request({
    url: `/rest/terminalParam/disable/${id}`,
    method: 'put'
  })
}
export const handleTerminalParamEnableAction = (id) => {
  return request({
    url: `/rest/terminalParam/enable/${id}`,
    method: 'put'
  })
}

// 参数模板禁用启用
export const handleTerminalParamTemplateDisableAction = (id) => {
  return request({
    url: `/rest/paramTemplate/disable/${id}`,
    method: 'put'
  })
}
export const handleTerminalParamTemplateEnableAction = (id) => {
  return request({
    url: `/rest/paramTemplate/enable/${id}`,
    method: 'put'
  })
}

export const getAllParameters = (value) => {
  return request({
    url: `/rest/paramTemplate/getAllParameter?option=${value}`,
    method: 'get'
  })
}

export const importParamToTerminalFromTemplate = (data) => {
  return request({
    url: `/rest/paramTemplate/importParam`,
    data: data,
    method: 'post'
  })
}
