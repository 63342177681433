import {
  getLimitsList,
  getTerminalModelList,
  getTerminalModelListByTenantId,
  getTenantList,
  getTerminalList,
  getUnselectTerminalList,
  getTerminalParamGroupList,
  getParamTemplateList,
  getGeoFenceGroupList,
  getGeoFenceList,
  getModuleList,
  getOperationTypeList,
  getApplicationList
} from '@/service/globalParams.js'
import { getUserList } from '@/service/user.js'
import { setItem } from '@/utils/storage'
import i18n from '@/i18n'

export default {
  namespaced: true,
  state: () => ({
    cacheObject: {}
  }),
  getters: {},
  mutations: {
    setCacheObject(state, { type, options }) {
      state.cacheObject[type] = options
    },
    clearCacheObject(state) {
      state.cacheObject = {}
    }
  },
  actions: {
    async getLimitsList() {
      const res = await getLimitsList()
      setItem('limitsList', res && res.data ? res.data : [])
      return res?.data || []
    },
    async getTerminalModelList() {
      const res = await getTerminalModelList()
      setItem('terminalModel', res?.data?.data || [])
      return res?.data?.data || []
    },
    async getTerminalModelListByTenantId(context, payload) {
      const res = await getTerminalModelListByTenantId(payload)
      return res?.data?.data || []
    },
    async getTenantList() {
      const res = await getTenantList()
      setItem('tenantList', res?.data?.data || [])
      return res?.data?.data || []
    },
    async getTerminalList() {
      const res = await getTerminalList()
      setItem('terminalList', res?.data?.data || [])
      return res?.data?.data || []
    },
    async getUnselectTerminalList() {
      const res = await getUnselectTerminalList()
      setItem('unselectTerminalList', res?.data?.data || [])
      return res?.data?.data || []
    },
    async getModuleList() {
      const res = await getModuleList()
      const { t } = i18n.global

      const data = res?.data?.data || []
      const options = data.map((item) => {
        const menuNameLocalValue = 'menu.' + item
        return { label: t(menuNameLocalValue), value: item }
      })
      return options
    },
    async getOperationTypeList() {
      const res = await getOperationTypeList()
      const data = res?.data?.data || []
      const options = data.map((item) => {
        return { label: item, value: item }
      })
      return options
    },
    async getTerminalParamGroupList() {
      const res = await getTerminalParamGroupList()
      console.log(res, 'getTerminalParamGroupList')
      setItem('terminalParamGroup', res?.data?.data || [])
      return res?.data?.data || []
    },
    async getParamTemplateList() {
      const res = await getParamTemplateList()
      setItem('paramTemplate', res?.data?.data || [])
      return res?.data?.data || []
    },
    async getUserList(context) {
      const res = await getUserList()
      console.log(res?.data?.data, 'getUserList')
      setItem('userList', res?.data?.data || [])
      return res?.data?.data || []
    },
    async getGeoFenceList(context) {
      const res = await getGeoFenceList()
      setItem('geoFenceList', res?.data?.data || [])
      return res?.data?.data || []
    },
    async getGeoFenceGroupList(context) {
      const res = await getGeoFenceGroupList()
      setItem('geoFenceGroupList', res?.data?.data || [])
      return res?.data?.data || []
    },
    async getApplicationList() {
      const res = await getApplicationList()
      setItem('application', res?.data?.data || [])
      return res?.data?.data || []
    }
  }
}
