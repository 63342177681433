/*****
 * 主要用来处理一些全局通用的函数
 */
import { ElMessage } from 'element-plus'
import i18n from '@/i18n'
import store from '@/store'
import { removeAllExcludeItem } from '@/utils/storage'
import router from '@/router/index.js'

export const handleMessageCommit = async (url, data = {}) => {
  // 这个国际化定义放在函数里面，不然有些情况会报错
  const { t } = i18n.global
  const res = await store.dispatch(url, data)
  return new Promise((resolve, reject) => {
    if (res?.data?.resultCode === 'SUCCESS' || res?.data?.code === 200) {
      ElMessage.success(
        (res?.data?.msg && t(res?.data?.msg)) ||
          (res?.data?.msg && t(res?.data?.msg)) ||
          t('popup.operation-success')
      )
      resolve()
    } else {
      ElMessage.error(
        (res?.data?.msg && t(res?.data?.msg)) ||
          (res?.data?.msg && t(res?.data?.msg)) ||
          t('popup.operation-failed')
      )
      reject(new Error('Error'))
    }
  })
}

// 定义一个异步函数，用于处理下载和导出逻辑
export const handleDownloadAndExport = async (
  progressModalRef,
  store,
  action,
  params,
  exportFn,
  reporttype,
  fileName
) => {
  try {
    // 打开进度模态框
    progressModalRef.value.handleOpenDialog()
    const { t } = i18n.global
    // 执行异步下载操作
    const res = await store.dispatch(action, params)
    // 如果下载成功，执行进度条动画和导出操作
    if (res.status === 200) {
      progressModalRef.value.handleRerenderInitialAnimate()
      setTimeout(() => {
        exportFn(reporttype, res, fileName)
      }, 1500)
    } else {
      console.error('Download failed.')
      ElMessage.error(t('general.fail-to-export-report-tip'))
      progressModalRef.value.closeDialog()
    }
  } catch (error) {
    // 捕获并处理错误
    console.error('An error occurred during download and export:', error)
    ElMessage.error('An error occurred during download and export')
    progressModalRef.value.closeDialog()
  }
}

export const handleLogout = async (isNeedDispatchUrl = true) => {
  // 调用此接口时需要注意是否异常情况导致的退出登录，如302、304、500等情况，就不需要调用接口退出登录，因为此时调用接口也是异常的
  const { t } = i18n.global
  let res = ''
  if (isNeedDispatchUrl) {
    res = await store.dispatch('user/handleLogout')
  }
  if (res?.data?.code === 200 || !isNeedDispatchUrl) {
    store.commit('app/setTagsView', [])
    if (isNeedDispatchUrl) {
      ElMessage.success(t('popup.logout-successful'))
      // 清除缓存
    }
    removeAllExcludeItem()
    // 关闭密码过期提示框
    store.commit('app/closePasswordExpiryNotification')
    router.push('/login')
  } else {
    ElMessage.error(t('popup.logout-failed'))
  }
}
