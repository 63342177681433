import layout from '@/Layout/index.vue'

export default {
  path: '/home',
  component: layout,
  redirect: '/home',
  meta: {
    title: 'home'
  },
  children: [
    {
      path: '/parameter/overview',
      name: 'Params',
      component: () => import('@/views/param/param/param-list/params.vue'),
      meta: {
        title: 'parameter-overview',
        searchFlag: true,
        isAuth: true,
        parentTitle: [
          {
            title: 'app-parameter'
          }
        ]
      }
    },
    {
      path: '/param/new',
      name: 'ParamNew',
      component: () =>
        import('@/views/param/param/param-handle/param-handle.vue'),
      meta: {
        title: 'parameter-new',
        searchFlag: true,
        isAuth: true,
        activeMenu: '/parameter/overview',
        parentTitle: [
          {
            title: 'app-parameter'
          },
          {
            title: 'app-parameter-params',
            path: '/parameter/overview'
          }
        ]
      }
    },
    {
      path: '/param/view/:id',
      name: 'ParamView',
      component: () =>
        import('@/views/param/param/param-handle/param-handle.vue'),
      meta: {
        title: 'parameter-view',
        searchFlag: true,
        isAuth: true,
        activeMenu: '/parameter/overview',
        parentTitle: [
          {
            title: 'app-parameter'
          },
          {
            title: 'app-parameter-params',
            path: '/parameter/overview'
          }
        ]
      }
    },
    {
      path: '/param/edit/:id',
      name: 'ParamEdit',
      component: () =>
        import('@/views/param/param/param-handle/param-handle.vue'),
      meta: {
        title: 'parameter-edit',
        searchFlag: true,
        isAuth: true,
        activeMenu: '/parameter/overview',
        parentTitle: [
          {
            title: 'app-parameter'
          },
          {
            title: 'app-parameter-params',
            path: '/parameter/overview'
          }
        ]
      }
    },
    {
      path: '/param/new/import',
      name: 'ParamImport',
      component: () =>
        import('@/views/param/param/param-list/param-import.vue'),
      meta: {
        title: 'parameter-import',
        searchFlag: true,
        isAuth: true,
        activeMenu: '/parameter/overview',
        parentTitle: [
          {
            title: 'app-parameter'
          },
          {
            title: 'app-parameter-params',
            path: '/parameter/overview'
          }
        ]
      }
    },
    {
      path: '/parameter/aid',
      name: 'AidOverview',
      component: () => import('@/views/param/aid/aid-list/index.vue'),
      meta: {
        title: 'parameter-aid',
        searchFlag: true,
        isAuth: true,
        parentTitle: [
          {
            title: 'app-parameter'
          }
        ]
      }
    },
    {
      path: '/parameter/aid/edit/:id',
      name: 'AidEdit',
      component: () => import('@/views/param/aid/aid-handle/index.vue'),
      meta: {
        title: 'parameter-aid-edit',
        searchFlag: true,
        isAuth: true,
        activeMenu: '/parameter/aid',
        parentTitle: [
          {
            title: 'app-parameter'
          },
          {
            title: 'app-parameter-aid',
            path: '/parameter/aid'
          }
        ]
      }
    },
    {
      path: '/parameter/aid/view/:id',
      name: 'AidView',
      component: () => import('@/views/param/aid/aid-handle/index.vue'),
      meta: {
        title: 'parameter-aid-view',
        searchFlag: true,
        isAuth: true,
        activeMenu: '/parameter/aid',
        parentTitle: [
          {
            title: 'app-parameter'
          },
          {
            title: 'app-parameter-aid',
            path: '/parameter/aid'
          }
        ]
      }
    },
    {
      path: '/parameter/aid/new/',
      name: 'AidNew',
      component: () => import('@/views/param/aid/aid-handle/index.vue'),
      meta: {
        title: 'parameter-aid-new',
        searchFlag: true,
        isAuth: true,
        activeMenu: '/parameter/aid',
        parentTitle: [
          {
            title: 'app-parameter'
          },
          {
            title: 'app-parameter-aid',
            path: '/parameter/aid'
          }
        ]
      }
    },
    {
      path: '/parameter/capk',
      name: 'CapkOverview',
      component: () => import('@/views/param/capk/capk-list/index.vue'),
      meta: {
        title: 'parameter-capk',
        searchFlag: true,
        isAuth: true,
        parentTitle: [
          {
            title: 'app-parameter'
          }
        ]
      }
    },
    {
      path: '/parameter/capk/edit/:id',
      name: 'CapkEdit',
      component: () => import('@/views/param/capk/capk-handle/index.vue'),
      meta: {
        title: 'parameter-capk-edit',
        searchFlag: true,
        isAuth: true,
        activeMenu: '/parameter/capk',
        parentTitle: [
          {
            title: 'app-parameter'
          },
          {
            title: 'app-parameter-capk',
            path: '/parameter/capk'
          }
        ]
      }
    },
    {
      path: '/parameter/capk/view/:id',
      name: 'CapkView',
      component: () => import('@/views/param/capk/capk-handle/index.vue'),
      meta: {
        title: 'parameter-capk-view',
        searchFlag: true,
        isAuth: true,
        activeMenu: '/parameter/capk',
        parentTitle: [
          {
            title: 'app-parameter'
          },
          {
            title: 'app-parameter-capk',
            path: '/parameter/capk'
          }
        ]
      }
    },
    {
      path: '/parameter/capk/new/',
      name: 'CapkNew',
      component: () => import('@/views/param/capk/capk-handle/index.vue'),
      meta: {
        title: 'parameter-capk-new',
        searchFlag: true,
        isAuth: true,
        activeMenu: '/parameter/capk',
        parentTitle: [
          {
            title: 'app-parameter'
          },
          {
            title: 'app-parameter-capk',
            path: '/parameter/capk'
          }
        ]
      }
    },
    {
      path: '/template/overview',
      name: 'TemplateOverview',
      component: () => import('@/views/param/template/template-list/index.vue'),
      meta: {
        title: 'template',
        searchFlag: true,
        isAuth: true,
        parentTitle: [
          {
            title: 'app-parameter'
          }
        ]
      }
    },
    {
      path: '/template/overview/new',
      name: 'TemplateNew',
      component: () =>
        import('@/views/param/template/template-handle/index.vue'),
      meta: {
        title: 'template-new',
        searchFlag: true,
        isAuth: true,
        activeMenu: '/template/overview',
        parentTitle: [
          {
            title: 'app-parameter'
          },
          {
            title: 'app-template',
            path: '/template/overview'
          }
        ]
      }
    },
    {
      path: '/template/overview/view/:id',
      name: 'TemplateView',
      component: () =>
        import('@/views/param/template/template-handle/index.vue'),
      meta: {
        title: 'template-view',
        searchFlag: true,
        isAuth: true,
        activeMenu: '/template/overview',
        parentTitle: [
          {
            title: 'app-parameter'
          },
          {
            title: 'app-template',
            path: '/template/overview'
          }
        ]
      }
    },
    {
      path: '/template/overview/config/:id',
      name: 'ConfigTerminalTemplate',
      component: () =>
        import('@/views/param/template/config-terminal-template/index.vue'),
      meta: {
        title: 'config-terminal-template',
        searchFlag: true,
        isAuth: true,
        activeMenu: '/template/overview',
        parentTitle: [
          {
            title: 'app-parameter'
          },
          {
            title: 'app-template',
            path: '/template/overview'
          }
        ]
      }
    },
    {
      path: '/template/overview/edit/:id',
      name: 'TemplateEdit',
      component: () =>
        import('@/views/param/template/template-handle/index.vue'),
      meta: {
        title: 'template-edit',
        searchFlag: true,
        isAuth: true,
        activeMenu: '/template/overview',
        parentTitle: [
          {
            title: 'app-parameter'
          },
          {
            title: 'app-template',
            path: '/template/overview'
          }
        ]
      }
    },
    {
      path: '/template/group',
      name: 'TemplateGroup',
      component: () =>
        import('@/views/param/template-group/template-group-list/index.vue'),
      meta: {
        title: 'template-group',
        searchFlag: true,
        isAuth: true,
        parentTitle: [
          {
            title: 'app-parameter'
          }
        ]
      }
    },
    {
      path: '/template/group/new',
      name: 'TemplateGroupNew',
      component: () =>
        import('@/views/param/template-group/template-group-handle/index.vue'),
      meta: {
        title: 'template-group-new',
        searchFlag: true,
        isAuth: true,
        activeMenu: '/template/group',
        parentTitle: [
          {
            title: 'app-parameter'
          },
          {
            title: 'app-template-group',
            path: '/template/group'
          }
        ]
      }
    },
    {
      path: '/template/group/view/:id',
      name: 'TemplateGroupView',
      component: () =>
        import('@/views/param/template-group/template-group-handle/index.vue'),
      meta: {
        title: 'template-group-view',
        searchFlag: true,
        isAuth: true,
        activeMenu: '/template/group',
        parentTitle: [
          {
            title: 'app-parameter'
          },
          {
            title: 'app-template-group',
            path: '/template/group'
          }
        ]
      }
    },
    {
      path: '/template/group/edit/:id',
      name: 'TemplateGroupEdit',
      component: () =>
        import('@/views/param/template-group/template-group-handle/index.vue'),
      meta: {
        title: 'template-group-edit',
        searchFlag: true,
        isAuth: true,
        activeMenu: '/template/group',
        parentTitle: [
          {
            title: 'app-parameter'
          },
          {
            title: 'app-template-group',
            path: '/template/group'
          }
        ]
      }
    },
    {
      path: '/template/setting',
      name: 'TemplateGroupSetting',
      component: () =>
        import(
          '@/views/param/template-group-setting/template-group-setting-list/index.vue'
        ),
      meta: {
        title: 'template-group-setting',
        searchFlag: true,
        isAuth: true,
        activeMenu: '/template/setting',
        parentTitle: [
          {
            title: 'app-parameter'
          }
        ]
      }
    },
    {
      path: '/template/setting/new',
      name: 'TemplateGroupSettingNew',
      component: () =>
        import(
          '@/views/param/template-group-setting/template-group-setting-handle/index.vue'
        ),
      meta: {
        title: 'template-group-setting-new',
        searchFlag: true,
        isAuth: true,
        activeMenu: '/template/setting',
        parentTitle: [
          {
            title: 'app-parameter'
          },
          {
            title: 'app-template-group-setting',
            path: '/template/setting'
          }
        ]
      }
    },
    {
      path: '/template/setting/view/:id',
      name: 'TemplateGroupSettingView',
      component: () =>
        import(
          '@/views/param/template-group-setting/template-group-setting-handle/index.vue'
        ),
      meta: {
        title: 'template-group-setting-view',
        searchFlag: true,
        isAuth: true,
        activeMenu: '/template/setting',
        parentTitle: [
          {
            title: 'app-parameter'
          },
          {
            title: 'app-template-group-setting',
            path: '/template/setting'
          }
        ]
      }
    },
    {
      path: '/template/setting/edit/:id',
      name: 'TemplateGroupSettingEdit',
      component: () =>
        import(
          '@/views/param/template-group-setting/template-group-setting-handle/index.vue'
        ),
      meta: {
        title: 'template-group-setting-edit',
        searchFlag: true,
        isAuth: true,
        activeMenu: '/template/setting',
        parentTitle: [
          {
            title: 'app-parameter'
          },
          {
            title: 'app-template-group-setting',
            path: '/template/setting'
          }
        ]
      }
    },
    {
      path: '/parameter/card',
      name: 'Card',
      component: () => import('@/views/param/card/card-list/index.vue'),
      meta: {
        title: 'card',
        searchFlag: true,
        isAuth: true,
        parentTitle: [
          {
            title: 'app-parameter'
          }
        ]
      }
    },
    {
      path: '/card/new',
      name: 'cardNew',
      component: () => import('@/views/param/card/card-new/index.vue'),
      meta: {
        title: 'card-new',
        searchFlag: true,
        isAuth: true,
        activeMenu: '/parameter/card',
        parentTitle: [
          {
            title: 'app-parameter'
          },
          {
            title: 'app-card-overview',
            path: '/parameter/card'
          }
        ]
      }
    },
    {
      path: '/card/edit/:id',
      name: 'cardEdit',
      component: () => import('@/views/param/card/card-edit/index.vue'),
      meta: {
        title: 'card-edit',
        searchFlag: true,
        activeMenu: '/parameter/card',
        isAuth: true,
        parentTitle: [
          {
            title: 'app-parameter'
          },
          {
            title: 'app-card-overview',
            path: '/parameter/card'
          }
        ]
      }
    },
    {
      path: '/card/view/:id',
      name: 'cardView',
      component: () => import('@/views/param/card/card-view/index.vue'),
      meta: {
        title: 'card-view',
        searchFlag: true,
        activeMenu: '/parameter/card',
        isAuth: true,
        parentTitle: [
          {
            title: 'app-parameter'
          },
          {
            title: 'app-card-overview',
            path: '/parameter/card'
          }
        ]
      }
    },
    // cardbin
    {
      path: '/parameter/card-bin',
      name: 'Cardbin',
      component: () => import('@/views/param/cardbin/cardbin-list/index.vue'),
      meta: {
        title: 'cardbin',
        searchFlag: true,
        isAuth: true,
        parentTitle: [
          {
            title: 'app-parameter'
          }
        ]
      }
    },
    {
      path: '/cardbin/new',
      name: 'cardbinNew',
      component: () => import('@/views/param/cardbin/cardbin-new/index.vue'),
      meta: {
        title: 'cardbin-new',
        searchFlag: true,
        isAuth: true,
        activeMenu: '/parameter/card-bin',
        parentTitle: [
          {
            title: 'app-parameter'
          },
          {
            title: 'app-cardbin-overview',
            path: '/parameter/card-bin'
          }
        ]
      }
    },
    {
      path: '/cardbin/edit/:id',
      name: 'cardbinEdit',
      component: () => import('@/views/param/cardbin/cardbin-edit/index.vue'),
      meta: {
        title: 'cardbin-edit',
        searchFlag: true,
        isAuth: true,
        activeMenu: '/parameter/card-bin',
        parentTitle: [
          {
            title: 'app-parameter'
          },
          {
            title: 'app-cardbin-overview',
            path: '/parameter/card-bin'
          }
        ]
      }
    },
    {
      path: '/cardbin/view/:id',
      name: 'cardbin',
      component: () => import('@/views/param/cardbin/cardbin-view/index.vue'),
      meta: {
        title: 'cardbin-view',
        searchFlag: true,
        isAuth: true,
        activeMenu: '/parameter/card-bin',
        parentTitle: [
          {
            title: 'app-parameter'
          },
          {
            title: 'app-cardbin-overview',
            path: '/parameter/card-bin'
          }
        ]
      }
    }
  ]
}
