import {
  LANG,
  TAGS_VIEW,
  BTN_PERMISSION_LIST,
  MENU_PERMISSION_LIST,
  RESOURCE_LIST,
  MAIN_COLOR,
  DEFAULT_COLOR,
  MAX_TAGS_VIEW_SIZE
} from '@/utils/constant/index'
import { getItem, setItem } from '@/utils/storage'
import { getMenuInfo, updateThemeColor } from '@/service/user'
import variables from '@/styles/variables.scss'

export default {
  namespaced: true,
  state: () => ({
    sidebarOpened: true,
    // 默认语言  en
    language: getItem(LANG) || 'en',
    tagsViewList: getItem(TAGS_VIEW) || [],
    btnPermissionList: getItem(BTN_PERMISSION_LIST) || [],
    mainColor: getItem(MAIN_COLOR) || DEFAULT_COLOR,
    variables,
    passwordExpiryNotification: null
  }),
  mutations: {
    setPasswordExpiryNotification(state, notification) {
      state.passwordExpiryNotification = notification
    },
    closePasswordExpiryNotification(state) {
      if (
        state.passwordExpiryNotification &&
        state.passwordExpiryNotification.close
      ) {
        state.passwordExpiryNotification.close()
        state.passwordExpiryNotification = null
      }
    },
    triggerSidebarOpened(state) {
      state.sidebarOpened = !state.sidebarOpened
    },
    // 设置国际化
    setLanguage(state, lang) {
      setItem(LANG, lang)
      state.language = lang
    },
    // 添加Tags
    addTagsViewList(state, tag) {
      const isfind = state.tagsViewList.find((item) => {
        return item.path === tag.path
      })
      if (!isfind) {
        if (state.tagsViewList.length >= MAX_TAGS_VIEW_SIZE) {
          state.tagsViewList.shift()
        }
        state.tagsViewList.push(tag)
        setItem('tagsView', state.tagsViewList)
      }
    },
    // 为指定的tag修改title
    changeTagsView(state, { index, tag }) {
      state.tagsViewList[index] = tag
      setItem(TAGS_VIEW, state.tagsViewList)
    },
    // 设置tagView
    setTagsView(state, tagsViewList) {
      state.tagsViewList = tagsViewList
      setItem(TAGS_VIEW, tagsViewList)
    },
    // 删除操作的时候同步将tagsViewList中相关的数据删掉
    removeTagsByCurrentId(state, payload) {
      state.tagsViewList = state.tagsViewList?.filter((item) => {
        return item.params.id !== payload
      })
    },
    // 移除tag
    removeTagsView(state, payload) {
      // 关闭当前
      if (payload.type === 'index') {
        state.tagsViewList.splice(payload.index, 1)
      } else if (payload.type === 'other') {
        state.tagsViewList.splice(
          payload.index + 1,
          state.tagsViewList.length - payload.index + 1
        )
        state.tagsViewList.splice(0, payload.index)
      } else if (payload.type === 'right') {
        state.tagsViewList.splice(
          payload.index + 1,
          state.tagsViewList.length - payload.index + 1
        )
      }
      setItem(TAGS_VIEW, state.tagsViewList)
    },
    // 移除指定TagView
    removeSpecifiedTagView(state, payload) {
      state.tagsViewList = state.tagsViewList.filter((item) => {
        return item.path !== payload.path
      })
      setItem(TAGS_VIEW, state.tagsViewList)
    },
    setBtnPermissionList(state, payload) {
      state.btnPermissionList = payload
      setItem(BTN_PERMISSION_LIST, payload)
    },
    /**
     * 设置主题颜色
     */
    setThemeColor(state, newColor) {
      setItem(MAIN_COLOR, newColor)
      state.mainColor = newColor
    }
  },
  actions: {
    // get Menu、btnPermission List
    async getMenuBtnPermissionList(context, payload) {
      const res = await getMenuInfo()
      const btnList = res?.data?.data?.btn || []
      const menuList = res?.data?.data?.url || []
      const resourceList = res?.data?.data?.resource || []
      this.commit('app/setBtnPermissionList', btnList)
      setItem(MENU_PERMISSION_LIST, menuList)
      setItem(RESOURCE_LIST, resourceList)
    },
    async updateThemeColor(context, payload) {
      const res = await updateThemeColor(payload)
      this.commit('app/setThemeColor', payload.themeColor)
      return res
    }
  }
}
